.musicWishRow {
    margin-bottom: 4rem;
}

@media (min-width: 733px) {
    .musicWishRow {
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        gap: 4%;
    }

    .musicWishRow > div {
        width: 100%;
    }
}