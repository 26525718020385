.content {
    height: 40rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.jumbotronImage {
    position: absolute;
    height: max(100%, 100vw / 2365.0 * 1744.0);
    pointer-events: none;
    z-index: 1;
}

.jumbotronText {
    position: absolute;
    z-index: 2;
    padding: 0 2.4rem;
    width: 100%;
    color: var(--color-jumbotron-font);
    text-align: center;
}

.jumbotronTitle,
.jumbotronSubtitle {
    color: var(--color-jumbotron-font);
    text-shadow: 2px 2px 1px #000000;
    font-family: var(--font-eyecatcher);
    font-weight: normal;
    margin: 0;
}

.jumbotronTitle {
    font-size: 4.0em;
}

.jumbotronSubtitle {
    font-size: 2.4em;
}

.separator {
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
}

.separator::before, 
.separator::after {
    content: "";
    width: 100%;
    height: 1px;
    display: block;
}

.separator::before {
    background: linear-gradient(to right, transparent 0%, var(--color-jumbotron-font) 70%)
}

.separator::after {
    background: linear-gradient(to left, transparent 0%, var(--color-jumbotron-font) 70%)
}

.separatorIcons {
    display: flex;
    font-size: 1.4rem;
    align-items: center;
    gap: 0.6rem;
    margin: 0 1.2rem;
}

.separatorIcons > :nth-child(2) {
    font-size: 1.3em;
}

@media (min-width: 750px) {
    .jumbotronTitle {
        font-size: 6.4em;
    }
    
    .jumbotronSubtitle {
        font-size: 3.2em;
    }
}
