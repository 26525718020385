.nav {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    gap: 5%;
    padding: 0 1.5rem;
    box-shadow: 0 0.7rem 1rem -0.2rem rgba(0,0,0,.15),0 0.3rem 0.5rem -0.1rem rgba(0,0,0,.06);
}

.names {
    text-align: center;
    font-family: var(--font-eyecatcher);
    font-size: 2.4em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
}

.names:focus,
.names:hover {
    text-decoration: none;
    color: var(--color-primary-darken);
}

.menuLink {
    font-size: 1.4em;
    padding: 0 0.5em;
    border-top: 0.4rem solid rgba(0,0,0,0);
    border-bottom: 0.4rem solid rgba(0,0,0,0);
    display: inline-flex;
    height: 100%;
    align-items: center;
}

.menuLink,
.menuLink:focus {
    color: var(--color-paragraph-font);
    text-decoration: none;
}

.menuLink.active {
    color: var(--color-primary);
    border-bottom-color: var(--color-primary);
}

.menuLink:hover {
    color: var(--color-primary-darken);
    text-decoration: none;
}

@media (min-width: 733px) {
    .nav {
        flex-direction: row;
    }

    .names {
        white-space: nowrap;
    }
}
