.item {
    list-style-type: none;
    display: flex;
    flex-flow: row nowrap;
    padding: 0.8rem;
    align-items: center;
}

.letter {
    font-family: var(--font-eyecatcher);
    font-size: 2.4rem;
    color: var(--color-primary);
    font-weight: bold;
    width: 2em;
    text-align: center;
}

.text {
    margin: 0;
}

@media (min-width: 733px) {
    .letter {
        font-size: 3.2rem;
    }
}
