.menuResponse {
    margin: 2rem 0 4rem 0;
}

.menuResponse p {
    margin: 0;
}

.topGroup {
    margin-bottom: 2rem;
}

.contactGroup {
    margin-bottom: 2rem;
}

@media (min-width: 733px) {
    .topGroup {
        display: flex;
        gap: 4%;
    }

    .contactGroup {
        width: 50%;
        margin-bottom: 0;
    }

    .isComingGroup {
        width: 50%;
    }
}