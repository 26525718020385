.selectContainer {
    width: 100%;
    position: relative;
}

.selectIcon {
    position: absolute;
    top: 1.25rem;
    right: 2.0rem;
    font-size: 1.5rem;
    pointer-events: none;
    color: var(--color-paragraph-font);
}

.selectIcon.invalid {
    color: var(--color-form-invalid);
}
