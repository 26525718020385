@font-face {
    font-display: swap;
    font-family: 'Lobster';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../fonts/lobster-v28-latin-regular.woff2') format('woff2'),
        url('../fonts/lobster-v28-latin-regular.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'Neucha';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('../fonts/neucha-v17-latin-regular.woff2') format('woff2'),
        url('../fonts/neucha-v17-latin-regular.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'Dancing Script';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../fonts/Dancing-Script-600.woff2') format('woff2'),
        url('../fonts/Dancing-Script-600.woff') format('woff');
}

@import url('./fontawesome-free-6.1.1/css/all.min.css');

:root {
    /*
    Primary colors
    */

    /* Rose, pink */
    /* --color-primary: #C7868F;
    --color-primary-darken: #B7636F; */

    /* Eucalyptus */
    --color-primary: #306b48;
    --color-primary-darken: #28583b;

    /* Strong eucalyptus */
    /* --color-primary: rgb(18, 104, 52);
    --color-primary-darken: rgb(14, 82, 41); */

    /*
    Section header
    */

    /* Light grey, pale blue */
    /* --color-header-section-background: #B6C8CE; */

    /* Yellow, gold */
    /* --color-header-section-background: rgb(255, 240, 155); */

    /* Light yellow */
    /* --color-header-section-background: #F9F3CC; */

    /* Cream */
    --color-header-section-background: #fff1d6;

    --main-background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("img/background_512_seamless.png");
    --color-font-on-primary: #ffffff;
    --color-paragraph-font: #333333;
    --color-footer-section-background: var(--color-primary);
    --color-footer-section-font: var(--color-font-on-primary);
    --color-scrollbutton-disabled-font: #D9D9D9;
    --color-form-border: #afafaf;
    --color-form-invalid: #dc3545;
    --color-alert-success-color: #2d5b2d;
    --color-alert-success-border: #9dc69d;
    --color-alert-success-background: #beeebe;
    --color-alert-danger-color: #58151c;
    --color-alert-danger-border: #f1aeb5;
    --color-alert-danger-background: #f8d7da;
    --color-form-input-disabled-background: #e0e0e0;
    --color-form-button-disabled-background: #cacaca;
    --font-default: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    --font-monospace: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    --font-eyecatcher: "Dancing Script";
    --color-btn-primary: var(--color-primary);
    --color-btn-primary-font: var(--color-font-on-primary);
    --color-btn-primary-darken: var(--color-primary-darken);
    --color-jumbotron-font: #ffffff;
    --color-location-overlay: var(--color-primary);
    --color-location-overlay-font: var(--color-font-on-primary);
}

*,
::before,
::after {
    box-sizing: inherit;
}

html {
    scroll-behavior: smooth;
}

html,
body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 10px;
    font-family: var(--font-default);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background: var(--main-background);
}

code {
    font-family: var(--font-monospace);
}

a,
.btn-link {
    color: var(--color-primary);
    text-decoration: none;
    transition: 0.2s ease-in-out;
    transition-property: color, background-color, transform, opacity
}

a:focus,
a:hover,
.btn-link:focus,
.btn-link:hover {
    text-decoration: underline;
}

button {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    overflow: visible;
    text-transform: none;
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1170px;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    width: 100%;
}

.align-center {
    text-align: center;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.mb-4 {
    margin-bottom: 4rem;
}

.mb-5 {
    margin-bottom: 5rem;
}

h1,
h2,
h3,
h4,
h5 {
    color: var(--color-paragraph-font);
    /*font-weight: normal;*/
    margin-top: 0;
    outline: none;
}

h1 {
    font-size: 3.2em;
}

h2 {
    font-size: 2.8em;
}

h3 {
    font-size: 2.4em;
}

h4 {
    font-size: 2.0em;
}

h5 {
    font-size: 1.6em;
}

.h-primary {
    color: var(--color-primary);
}

p {
    color: var(--color-paragraph-font);
    font-size: 1.6em;
}

section {
    padding: 3em 0;
}

.section-header {
    padding: 0;
}

.section-headline {
    font-family: var(--font-eyecatcher);
    font-weight: normal;
    margin-bottom: 0;
}

.header-section {
    background-color: var(--color-header-section-background);
}

.header-section h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: block;
    background: rgba(0, 0, 0, 0.1);
    margin: 0.6em auto 0 auto;
}

.header-section p {
    margin-bottom: 0;
    font-size: 1.8em;
}

.footer-section {
    background-color: var(--color-footer-section-background);
    padding: 1.5em 0;
}

.footer-section h2 {
    font-family: var(--font-eyecatcher);
    font-weight: normal;
    color: var(--color-footer-section-font);
    font-size: 2.5em;
    margin: 0;
}

.form-row {
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;
    gap: 4%;
}

.fadeable {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: 0.5s ease-in-out;
    transition-property: opacity;
}

.fadeable.visible {
    height: auto;
    opacity: 1;
}

fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

input[type=text],
select,
textarea {
    appearance: none;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    border-radius: 0.4rem;
    border: 1px solid var(--color-form-border);
    color: var(--color-paragraph-font);
    font-family: var(--font-default);
    font-size: 1.6rem;
    margin: 0;
    padding: 1.2rem;
    outline: none;
    width: 100%;
    transition: 0.2s ease-in-out;
    transition-property: background-color;
}

input[type=text] {
    line-height: 2rem;
}

input[type=text]:disabled,
select:disabled,
textarea:disabled {
    background: var(--color-form-input-disabled-background);
}

option {
    color: var(--color-paragraph-font);
}

input[type=text].invalid,
select.invalid {
    border-color: var(--color-form-invalid);
    color: var(--color-form-invalid);
}

.validation-message {
    color: var(--color-form-invalid);
    font-size: 1.4rem;
    padding: 0.5rem;
}

select {
    padding-right: 3.5rem;
}

textarea {
    line-height: 2.4rem;
}

input[type=radio] {
    margin: 0.4rem;
    padding: 0;
}

label {
    color: var(--color-paragraph-font);
    font-family: var(--font-default);
    font-size: 1.6rem;
}

.form-radio {
    gap: 0.4rem;
}

.form-radio label {
    width: 100%;
}

.btn {
    outline: none;
    border: 0;
    border-radius: 0.4rem;
    width: 100%;
    padding: 1.2rem 0.8rem;
    font-size: 1.6rem;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    transition-property: color, background-color;
}

.btn-primary {
    background: var(--color-btn-primary);
    color: var(--color-btn-primary-font);
}

.btn-primary:focus,
.btn-primary:hover {
    background: var(--color-btn-primary-darken);
}

.btn-primary:disabled {
    background: var(--color-form-button-disabled-background);
    cursor: default;
}

.ul-menu {
    font-size: 1.6em;
}

.ul-menu li {
    line-height: 1.6em;
    color: var(--color-paragraph-font);
    list-style: none;
}

.ul-menu li:before {
    content: "•";
    padding-right: 0.5rem;
}

.faq-p {
    margin-top: 0;
    margin-bottom: 2.8rem;
}

@media (min-width: 733px) {
    h1 {
        font-size: 4.8em;
    }

    h2 {
        font-size: 4em;
    }

    h3 {
        font-size: 3.2em;
    }

    h4 {
        font-size: 2.4em;
    }

    h5 {
        font-size: 1.6em;
    }
}
