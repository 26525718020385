.weddingAbc {
    margin: 0;
    padding: 0;
    columns: 1;
}

@media (min-width: 733px) {
    .weddingAbc {
        columns: 2;
    }
}
