.alert {
    border: 1px solid;
    border-radius: 0.4rem;
}

.alertSuccess {
    background: var(--color-alert-success-background);
    border-color: var(--color-alert-success-border);
    --color-paragraph-font: var(--color-alert-success-color);

}

.alertDanger {
    background: var(--color-alert-danger-background);
    border-color: var(--color-alert-danger-border);
    --color-paragraph-font: var(--color-alert-danger-color);
}
