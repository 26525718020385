.organizersImage {
    display: flex;
    justify-content: center;
}

.organizersImage img {
    width: 90%;
    box-shadow: 0px 0px 25px #ffffff;
}

@media (min-width: 733px) {
    .organizers {
        display: flex;
        gap: 5%;
        justify-content: center;
    }

    .organizersImage img {
        width: calc(90% - (100vw - 733px) * 0.63);
    }
}

@media (min-width: 1170px) {
    .organizersImage img {
        width: 65%;
    }
}