.organizerItem {
    text-align: center;
    margin: 4rem 0;
}

.organizerImage {
    border-radius: 50%;
    height: 15rem;
    margin-bottom: 1rem;
}

.organizerName {
    font-size: 2em;
    margin: 0;
}

.organizerSubtitle {
    font-style: italic;
    font-size: 1.4em;
    margin: 0;
}

.organizerEmail {
    margin: 1rem 0 0 0;
}

.organizerPhone {
    margin: 0;
}