.locationMap {
    text-align: center;
    margin: 3rem 0;
}

.locationMapImage {
    margin-bottom: 1em;
}

.locationMapLink {
    position: relative;
    display: inline-block;
}

.overlay {
    fill-opacity: 0;
    fill: var(--color-location-overlay);
    transition: fill-opacity .5s ease-in-out;
}

.locationMapLink:focus .overlay,
.locationMapLink:hover .overlay {
    fill-opacity: 0.8;
}

.overlayIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: var(--color-location-overlay-font);
    font-size: 5em;
}

.locationMapLink:focus .overlayIcon,
.locationMapLink:hover .overlayIcon {
    opacity: 1;
}

.locationTitle {
    margin-bottom: 0.1em;
    font-size: 2.4em;
}

.locationSubtitle {
    margin-top: 0;
    margin-bottom: 0;
    font-style: italic;
    font-size: 1.4em;
}

.locationUri {
    font-size: 1.4em;
}

@media (min-width: 733px) {
    .locationMap {
        display: flex;
        align-items: center;
        margin: 2rem 0;
    }

    .locationMapImage {
        align-self: flex-start;
        margin-right: 2.5em;
    }

    .locationDetails {
        text-align: left;
    }
}
