.accordionItem {
    border-bottom: 1px solid var(--color-primary);
}

.accordionContentOuter {
    overflow: hidden;
    transition: height 0.5s ease;
}

.accordionContentInner {
    overflow: hidden;
    padding: 0 1.6rem;
}

.title {
    outline: none;
    border: 0;
    background: none;
    margin: 0;
    padding: 1.6rem;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    font-size: 1.7em;
    font-weight: bold;
    color: var(--color-paragraph-font);
    width: 100%;
    text-align: left;
}

.title:hover {
    color: var(--color-primary);
}

.title i {
    color: var(--color-primary);
    margin-right: 0.8rem;
    transition: transform 0.5s ease;
}

.open i {
    transform: rotate(90deg);
}
