.wrapper {
    display: flex;
    min-height: 4.8em;
    width: 100%;
}

.scrollButton {
    padding: 0 1rem;
    font-size: 2.4rem;
    background: none;
    border: 0;
    cursor: pointer;
    opacity: 1;
}

.scrollButton:focus,
.scrollButton:hover {
    color: var(--color-primary-darken);
}

.scrollButton:disabled {
    color: var(--color-scrollbutton-disabled-font);
}

.unscrollable .scrollButton {
    opacity: 0;
}

.scrollOuter {
    width: 100%;
    touch-action: pan-y;
    user-select: none;
    position: relative;
    overflow: hidden;
}

.unscrollable .scrollOuter {
    display: flex;
    justify-content: flex-end;
}

.scrollInner {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    position: absolute;
    white-space: nowrap;
    transition: 0.5s ease-in-out;
    height: 100%;
}
